import React, { useEffect, useState } from "react";
import Img from "gatsby-image";
import SliderClients from "components/SliderClients";
import Title from "components/Title";
import * as S from "./Clients.styles";
import { useIntl } from "gatsby-plugin-intl";
import images from "./Clients.mock.js";

const settings = {
  slidesToShow: 8,
  infinite: false,
  responsive: [
    {
      breakpoint: 1370,
      settings: {
        slidesToShow: 6,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        arrows: false,
        slidesToShow: 2.5,
        slidesToScroll: 1,
      },
    },
  ],
};

const Clients = () => {
  const { formatMessage } = useIntl();
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const isMobile = windowSize?.width < 768;

  // function useWindowSize() {

  //     function handleResize() {
  //       setWindowSize({
  //         width: window.innerWidth,
  //         height: window.innerHeight,
  //       })
  //     }

  //     window.addEventListener("resize", handleResize)
  //     handleResize()

  //     return () => window.removeEventListener("resize", handleResize)

  //   return windowSize
  // }

  return (
    <S.Wrapper id="clients">
      <Title>{formatMessage({ id: "clients.title" })}</Title>
      <S.SlideWrapper>
        {images?.map(({ index, image }) => {
          // Não renderiza o item com índice 27 em dispositivos móveis
          if (isMobile && index === 27) return null;

          return (
            <S.CardImage key={index} index={index}>
              <S.Img src={image} />
            </S.CardImage>
          );
        })}
      </S.SlideWrapper>
    </S.Wrapper>
  );
};

export default Clients;
