export default [
  {
    id: 0,
    alt:
      "TM1 Live Streaming - Vencedora do Prêmio Live como melhor plataforma de live streaming de 2021",
  },
  {
    id: 1,
    alt: "Netflix La Casa de Papel - CCPX 2018",
  },
  {
    id: 2,
    alt: "Conecta Imobi 2022",
  },
  {
    id: 3,
    alt: "Meta Summit 2022",
  },
  {
    id: 4,
    alt: "Bayer - Show Rural Copavel",
  },
  {
    id: 5,
    alt: "Crie o impossivel",
  },
  {
    id: 6,
    alt: "Netflix Stranger Things",
  },
  {
    id: 7,
    alt: "Netflix Stranger Things",
  },
];
